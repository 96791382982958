import { useState, useEffect, useRef } from 'react';
import WebAudioSpeechRecognizer from '../../../public/tencent-speech-sdk/webaudiospeechrecognizer';
import signCallback from '@/utils/asrauthentication';
import MicrophoneOpen from '@/assets/img/microphone_open.svg'
import MicrophoneClose from '@/assets/img/microphone_close.svg'
import styles from './speechToText.module.less'
import { Modal } from 'antd';
import VoiceInputMICIcon from '@/assets/img/voice_input_mic.svg';
import VoiceInputMICActiveIcon from '@/assets/img/voice_input_mic_active.svg';
import VoiceInputStartIcon from '@/assets/img/voice_input_start.svg';
import VoiceInputStopIcon from '@/assets/img/voice_input_stop.svg';
import VoiceInputAgainIcon from '@/assets/img/voice_input_again.svg';
import VoiceInputAcceptIcon from '@/assets/img/voice_input_accept.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

interface SpeechToTextProps {
    setInputMessage: (message: string) => void;
    inputMessage: string;
    microphoneIcon?: string;
}

const SpeechToText: React.FC<SpeechToTextProps> = ({
    setInputMessage,
    inputMessage,
    microphoneIcon = MicrophoneClose
}) => {
    const { lectureStore } = useStores();
    const [text, setText] = useState('');
    const [isRecording, setIsRecording] = useState(false);//是否正在录音
    const [isHovered, setIsHovered] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRecordingEnd, setIsRecordingEnd] = useState(false);
    let speechRecognizer = useRef<WebAudioSpeechRecognizer | null>(null);

    const params = {
        signCallback: signCallback, // 用户填写的鉴权函数
        // 用户参数
        secretid: 'AKIDxhcP4Xu54oMgmdNvKYa4OZacVDj3IQQH',
        secretkey: 'mSyJWaS9eyA3xpbBfzFMMAlDlJnfT9j5',
        appid: 1317917038,
        // 实时识别接口参数
        engine_model_type: '16k_zh',
    }

    const handleStart = () => {
        speechRecognizer.current = new WebAudioSpeechRecognizer(params);
        speechRecognizer.current.OnRecognitionStart = (res) => {
            console.log('开始识别', res);
        };
        speechRecognizer.current.OnSentenceBegin = (res) => {
            console.log('一句话开始', res);
        };
        speechRecognizer.current.OnRecognitionResultChange = (res) => {
            console.log('识别变化时', res);
            // const currentText = `${inputMessage}${res.result.voice_text_str}`;
            // setText(currentText);
            // setInputMessage(currentText);
            setText(res.result.voice_text_str);
        };
        speechRecognizer.current.OnSentenceEnd = (res) => {
            console.log('一句话结束', res);
            const currentText = `${text}${res.result.voice_text_str}`;
            setText(currentText);
        };
        speechRecognizer.current.OnRecognitionComplete = (res) => {
            console.log('识别结束', res);
        };
        // 识别错误
        speechRecognizer.current.OnError = (res) => {
            console.log('识别失败', res);
        };
        speechRecognizer.current.start();
        setIsRecording(true);
    };

    const handleStop = () => {
        setIsRecording(false);
        setIsRecordingEnd(true);
        if (speechRecognizer.current) {
            speechRecognizer.current.stop();
        }
        //一秒后调用handleAccept
        setTimeout(() => {
            handleAccept();
            setIsRecordingEnd(false);
            if (lectureStore.courseBGM) {
                lectureStore.playCourseBGM();
            }
        }, 1000);
    };

    const handleCancel = () => {
        handleStop();
        setText('');
        setIsModalVisible(false);
        if (lectureStore.courseBGM) {
            lectureStore.playCourseBGM();
        }
    }

    const handleAccept = () => {
        setInputMessage(`${inputMessage}${text}`);
        setIsModalVisible(false);
        setText('');
    }

    return (
        <div
            className={styles.recordBtn}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                src={isRecording || isHovered ? MicrophoneOpen : microphoneIcon}
                onMouseDown={(e) => {
                    e.preventDefault();
                    setIsModalVisible(true);
                    if (lectureStore.courseBGM) {
                        lectureStore.pauseCourseBGM();
                    }
                }
                }
            />
            <Modal
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
                className={styles.voiceInputModal}
            >
                <div className={styles.voiceInputBox}>
                    <div className={styles.title}>
                        {
                            !isRecording && !isRecordingEnd && <>请点击开始说话~~~</>
                        }
                        {
                            (isRecording || isRecordingEnd) && <>{text}</>
                        }
                    </div>
                    <div className={styles.micImg}>
                        <img src={isRecording ? VoiceInputMICActiveIcon : VoiceInputMICIcon} />
                    </div>
                    <div className={styles.operationBtn}>
                        {
                            !isRecording && !isRecordingEnd && <img src={VoiceInputStartIcon} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleStart();
                            }} />
                        }
                        {
                            isRecording && <img src={VoiceInputStopIcon} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleStop();
                            }} />
                        }
                        {
                            isRecordingEnd && <img src={VoiceInputAcceptIcon} />
                        }

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default observer(SpeechToText);