import { Modal, Input, InputNumber, Button, Radio } from "antd";
import { useState, useEffect } from "react";
import ProgressSegmentData from '@/base/ProgressSegmentData';
import UploadMedia from "../UploadMedia/UploadMedia";
import styles from "./createCourseModal.module.less";

interface CreateCourseModalProps {
    isModalOpen: boolean;
    handleOk: (segments: ProgressSegmentData[]) => void;
    handleCancel: () => void;
    inputCourseName: string;
    inputCourseIntroduce: string;
    courseCoverUrl: string;
    courseKeyPoints: string[];
    coursePartSegments: ProgressSegmentData[];
    courseVisibleToNormalUsers: boolean;
    courseBGM: string;
    courseNameChange: (e: any) => void;
    courseCoverUrlChange: (url: string) => void;
    courseIntroduceChange: (e: any) => void;
    courseKeyPointChange: (index: number, value: string) => void;
    coursePartSegmentsChange: (index: number, key: string, value: any) => void;
    courseVisibleToNormalUsersChange: (value: boolean) => void;
    courseBGMChange: (url: string) => void;

    totalBlocks: number;
    isCreating: boolean;
};


const CreateCourseModal: React.FC<CreateCourseModalProps> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    inputCourseName,
    inputCourseIntroduce,
    courseCoverUrl,
    courseKeyPoints,
    coursePartSegments,
    courseVisibleToNormalUsers,
    courseBGM,
    courseNameChange,
    courseCoverUrlChange,
    courseIntroduceChange,
    courseKeyPointChange,
    coursePartSegmentsChange,
    courseVisibleToNormalUsersChange,
    courseBGMChange,

    totalBlocks,
    isCreating,
}) => {
    const [localPartSegments, setLocalPartSegments] = useState(coursePartSegments || []);
    //const [isVisibleToNormalUsers, setIsVisibleToNormalUsers] = useState(courseVisibleToNormalUsers);
    useEffect(() => {
        setLocalPartSegments(coursePartSegments || []);
    }, [coursePartSegments]);

    const addPart = () => {
        const newPart = new ProgressSegmentData({
            endPos: 0,
            desc: ""
        });
        setLocalPartSegments([...localPartSegments, newPart]);
        coursePartSegmentsChange(0, "new", null);
    };

    const delPart = (index: number) => {
        const newPartSegments = [...localPartSegments];
        newPartSegments.splice(index, 1);
        setLocalPartSegments(newPartSegments);
        coursePartSegmentsChange(0, "del", index);
    };

    const onRadioChange = (e: any) => {
        courseVisibleToNormalUsersChange(e.target.value);
    };

    return (
        <Modal
            title="课程名称"
            open={isModalOpen}
            onOk={() => handleOk(localPartSegments)}
            onCancel={handleCancel}
            centered
            okText="确认"
            cancelText="取消"
            confirmLoading={isCreating} // 设置Modal的confirmLoading状态为isCreating的值
        >
            <UploadMedia
                successCallBack={courseCoverUrlChange}
                btn_text={"上传封面"}
                accept_str={"image/*"}
                dir={"images"}
                isMultiple={false}
            />
            <div className={styles.cover}>
                {courseCoverUrl && <img src={courseCoverUrl} alt="" />}
            </div>
            <Input placeholder="请输入课程名称" value={inputCourseName} onChange={courseNameChange}></Input>
            <Input placeholder="请输入课程介绍" value={inputCourseIntroduce} onChange={courseIntroduceChange}></Input>
            <div className={styles.bgmBox}>
                <UploadMedia
                    successCallBack={courseBGMChange}
                    btn_text={"修改背景音乐"}
                    accept_str={"audio/*"}
                    dir={"audio"}
                    isMultiple={false}
                />
                {courseBGM ? <audio src={courseBGM} controls /> : <div>暂无背景音乐</div>}
            </div>
            <div>课程目标</div>
            <Input placeholder="请输入课程目标 1" value={courseKeyPoints[0]} onChange={e => courseKeyPointChange(0, e.target.value)}></Input>
            <Input placeholder="请输入课程目标 2" value={courseKeyPoints[1]} onChange={e => courseKeyPointChange(1, e.target.value)}></Input>
            <Input placeholder="请输入课程目标 3" value={courseKeyPoints[2]} onChange={e => courseKeyPointChange(2, e.target.value)}></Input>
            <br />
            <div>总共{totalBlocks}个Blocks</div>
            <Radio.Group onChange={onRadioChange} value={courseVisibleToNormalUsers}>
                <Radio value={true}>普通用户可见</Radio>
                <Radio value={false}>普通用户不可见</Radio>
            </Radio.Group>
            <br />
            <div>
                <div>配置Part信息</div>
                <br />
                {localPartSegments.map((item, index) => (
                    <div key={"parts" + String(index)}>
                        <h4>part {index}</h4>
                        <div >endPos</div>
                        <InputNumber
                            value={item.endPos}
                            onChange={(value) => coursePartSegmentsChange(index, 'endPos', value)} />
                        <div >Description</div>
                        <Input placeholder="Part描述"
                            value={item.desc}
                            onChange={e => coursePartSegmentsChange(index, 'desc', e.target.value)}
                            className={styles.textareaVar} />
                        <Button onClick={() => delPart(index)}>删除</Button>
                    </div>)
                )}
                <Button onClick={addPart}>添加Part</Button>
            </div>
        </Modal>
    )
}

export default CreateCourseModal;