import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'

interface MarkdownWithHighlightProps {
    content: string;
}

const MarkdownWithHighlight: React.FC<MarkdownWithHighlightProps> = ({ content }) => {

    const components = {
        code(props: any) {
            const { children, className, node, ...rest } = props
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
                <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    language={match[1]}
                    style={tomorrow}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code {...rest} className={className}>
                    {children}
                </code>
            )
        }
    };

    return (
        <ReactMarkdown components={components}>
            {content}
        </ReactMarkdown>
    )
}

export default MarkdownWithHighlight;