import { QRCode } from 'antd';
import { useEffect, useState } from 'react';
import styles from './qRCodeRegion.module.less';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { convertToCloudFrontUrl } from '@/utils/utils';
import { getSongInfo } from '@/api/userLikeObject';
import { createUserWork } from '@/api/userWork';
import { generateMD5 } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { message } from 'antd';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';

const QRCodeRegion: React.FC<IntrinsicElementProps<QRCodeRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete }) => {
    const { src = '', qrCodeType, shareType, payload = {} } = elementData.content;
    const { userInfoStore, lectureStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const [targetUrl, setTargetUrl] = useState('');
    const [qrLoading, setQrLoading] = useState(false);

    const generateMusicQRCode = async () => {
        try {
            setQrLoading(true);
            const getSongRes = await getSongInfo(userInfoStore.userInfoData.id, lectureStore.getLectureVar("sono_song_id"));
            if (getSongRes.status === 'success') {
                const songData = { ...getSongRes.data, author_name: userInfoStore.userInfoData.name };
                const concatenatedString = `${songData.author_id}${songData.id}${songData.name}`;
                const MD5 = generateMD5(concatenatedString);
                const shareLinkData = {
                    id: 0,
                    md5: MD5,
                    user_id: userInfoStore.userInfoData.id,
                    user_name: userInfoStore.userInfoData.name,
                    work_type: "music",
                    work_info: songData,
                    create_time: 0
                };
                const shareLinkRes: any = await createUserWork(shareLinkData);
                if (shareLinkRes.status === 'success') {
                    setTargetUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
                    setQrLoading(false);
                } else {
                    setQrLoading(false);
                    message.error('生成分享链接失败');
                }
            } else {
                throw new Error('获取歌曲信息失败');
            }
        } catch (error) {
            setQrLoading(false);
            message.error((error as Error).message || '出错了');
        }
    };

    //生成除了音乐以外的二维码
    const generateOtherQRCode = async (shareType: string) => {
        let newPayload: any = {};
        Object.keys(payload).forEach(key => {
            if (containsVariablePattern(payload[key])) {
                newPayload[key] = rewriteText(payload[key]);
            } else {
                newPayload[key] = payload[key];
            }
        });

        try {
            setQrLoading(true);
            let concatenatedString = '';
            if (shareType === 'code') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.srcCode}${newPayload.language}`;
            } else if (shareType === 'cyberAvatar') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.avatar_url}${newPayload.name}${newPayload.tagline}`;
            } else if (shareType === 'basicPost') {
                concatenatedString = `${userInfoStore.userInfoData.id}${newPayload.title}${newPayload.body}${newPayload.imgUrl}${newPayload.backgroundStyle}`;
            }
            const MD5 = generateMD5(concatenatedString);
            const shareLinkData = {
                id: 0,
                md5: MD5,
                user_id: userInfoStore.userInfoData.id,
                user_name: userInfoStore.userInfoData.name,
                work_type: shareType,
                work_info: newPayload,
                create_time: 0
            };
            const shareLinkRes: any = await createUserWork(shareLinkData);
            if (shareLinkRes.status === 'success') {
                setTargetUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
                setQrLoading(false);
            } else {
                setQrLoading(false);
                message.error('生成分享链接失败');
            }
        } catch (error) {
            setQrLoading(false);
            message.error((error as Error).message || '出错了');
        }
    };

    // useEffect(() => {
    //     if (containsVariablePattern(elementData.content.src)) {
    //         if (elementData.content.src === "{{suno_song}}") {
    //             if (lectureStore.getLectureVar("sono_song_id")) {
    //                 generateShareInfo();
    //             }
    //         }
    //     } else {
    //         setTargetUrl(elementData.content.src);
    //     }

    // }, [elementData.content.src]);
    useEffect(() => {
        if (qrCodeType === 'preset') {
            setTargetUrl(src);
        } else if (qrCodeType === 'generate') {
            if (isTeacher) {
                setTargetUrl('这是老师视角');
                return;
            }
            if (shareType === 'music' && lectureStore.getLectureVar("sono_song_id")) {
                generateMusicQRCode();
            } else {
                generateOtherQRCode(shareType);
            }
        }
    }, [])

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            {...(isEditable && { 'data-findparent': 'findparent' })}
        >
            <QRCode
                value={convertToCloudFrontUrl(targetUrl)|| "暂无内容"}
                className={styles.qrCode}
                status={qrLoading ? 'loading' : 'active'}
            />
        </div>
    </BaseDragableElement>);
};

export default observer(QRCodeRegion);
