import styles from './audioRegion.module.less';
import PropTypes from 'prop-types';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import { useState, useEffect, MouseEvent, TouchEvent } from 'react';
import MusicRhythmIcon from '@/assets/img/music_rhythm.svg';
import LikeIcon from '@/assets/img/heart_red.svg';
import UnLikeIcon from '@/assets/img/heart_grey.svg';
import ShareIcon from '@/assets/img/share_icon.svg';
import DownloadIcon from '@/assets/img/download_icon.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { QRCode, Tooltip, message } from 'antd';
import { likeObjectAndUpdate, unlikeObjectAndUpdate, userShareObject, getLikeCnt } from '@/api/userLikeObject';
import { convertToCloudFrontUrl } from '@/utils/utils';
import { getSongInfo } from '@/api/userLikeObject';
import { createUserWork } from '@/api/userWork';
import { generateMD5 } from '@/utils/utils';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import AudioRegionContent from '@/base/ElementData/AudioRegionContent';
import { LectureStore } from '@/store/lectureStore';

interface AudioRegionProps extends IntrinsicElementProps<AudioRegionContent> {
    lectureStore?: LectureStore;
}

const getFinalContent = (content: AudioRegionContent) => ({
    title: containsVariablePattern(content.title) ? rewriteText(content.title) : content.title,
    artist: containsVariablePattern(content.artist) ? rewriteText(content.artist) : content.artist,
    lyrics: containsVariablePattern(content.lyrics) ? rewriteText(content.lyrics) : content.lyrics,
    src: content.src,
    songCoverUrl: content.songCoverUrl,
    playerType: content.playerType,
});

const AudioRegion: React.FC<AudioRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    lectureStore }) => {
    const { userInfoStore } = useStores();
    const [duration, setDuration] = useState<number | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [songId, setSongId] = useState(0);
    const [content, setContent] = useState(new AudioRegionContent({}));
    const [qrLoading, setQrLoading] = useState(false);
    const [qrUrl, setQrUrl] = useState('null');
    const [likeCount, setLikeCount] = useState(0);
    const { src, title, lyrics, songCoverUrl, playerType, artist } = elementData.content;


    useEffect(() => {
        const content = getFinalContent(elementData.content);
        if (lectureStore) {
            if (lectureStore.getLectureVar("sono_song")) {
                content.src = lectureStore.getLectureVar("sono_song");
            }
            if (lectureStore.getLectureVar("sono_song_title")) {
                content.title = lectureStore.getLectureVar("sono_song_title");
            }
            if (lectureStore.getLectureVar("sono_song_lyrics")) {
                content.lyrics = lectureStore.getLectureVar("sono_song_lyrics").replace(/\[.*?\]/g, ' ');
            }
            if (lectureStore.getLectureVar("sono_song_photo")) {
                content.songCoverUrl = lectureStore.getLectureVar("sono_song_photo");
            }
            if (lectureStore.getLectureVar("sono_song_id")) {
                setSongId(lectureStore.getLectureVar("sono_song_id"));
            }
        }
        setContent(new AudioRegionContent(content));
    }, [src, title, lyrics, songCoverUrl, playerType, artist]);

    const handleClick = (e: any) => {
        if (isEditable) handleFocusItem(elementData, e);
    };

    const getLikeCount = async () => {
        try {
            const res = await getLikeCnt({ object_id: songId });
            setLikeCount(res.data);
        } catch (error) {
            console.error('Error getting like count:', error);
        }
    }

    const clickLikeIcon = async () => {
        if (!userInfoStore.userInfoData.id || !songId) {
            return;
        }
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_id: songId,
            object_type: 'music',
            create_time: 0
        }
        if (isLiked) {
            setIsLiked(false);
            const res = await unlikeObjectAndUpdate(data);
            setLikeCount(res.data);
        } else {
            setIsLiked(true);
            const res = await likeObjectAndUpdate(data);
            setLikeCount(res.data);
        }
    }

    const clickShareIcon = async () => {
        if (!userInfoStore.userInfoData.id || !songId) {
            return;
        }
        setQrLoading(true);
        const data = {
            user_id: userInfoStore.userInfoData.id,
            object_type: "music",
            object_id: songId,
            channel: "classroom",
            extras: {},
            create_time: 0
        };
        try {
            const res = await userShareObject(data);
            if (res.status === 'success') {
                generateShareInfo();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const generateShareInfo = async () => {
        try {
            const getSongRes = await getSongInfo(userInfoStore.userInfoData.id, songId);
            if (getSongRes.status === 'success') {
                const songData = { ...getSongRes.data, author_name: userInfoStore.userInfoData.name };
                const concatenatedString = `${songData.author_id}${songData.id}${songData.name}`;
                const MD5 = generateMD5(concatenatedString);
                const shareLinkData = {
                    id: 0,
                    md5: MD5,
                    user_id: userInfoStore.userInfoData.id,
                    user_name: userInfoStore.userInfoData.name,
                    work_type: "music",
                    work_info: songData,
                    create_time: 0
                };
                const shareLinkRes: any = await createUserWork(shareLinkData);
                if (shareLinkRes.status === 'success') {
                    setQrUrl(`${import.meta.env.VITE_FE_URL}/share/${MD5}`);
                    setQrLoading(false);
                } else {
                    setQrLoading(false);
                    message.error('生成分享链接失败');
                }
            } else {
                throw new Error('获取歌曲信息失败');
            }
        } catch (error) {
            setQrLoading(false);
            if (error instanceof Error) {
                message.error(error.message || '出错了');
            } else {
                message.error('出错了');
            }
        }
    };

    const clickDownloadIcon = async () => {
        if (!content?.src) return;
        try {
            const response = await fetch(content?.src);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${content?.title}.mp3`; // 或者使用其他格式  
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl); // 释放blob URL  
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    useEffect(() => {
        if (content?.src) {
            const audio = new Audio(content.src);
            audio.onloadedmetadata = () => {
                setDuration(audio.duration);
            };
        }
    }, [content.src]);

    useEffect(() => {
        if (songId) {
            getLikeCount();
        }
    }, [songId])
    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={handleClick}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                {
                    content?.playerType === 'small' && (
                        <div className={styles.smallAudioBox}>
                            <div className={styles.smallCover}>
                                {content?.songCoverUrl ? (
                                    <img
                                        src={content?.songCoverUrl}
                                        alt="song cover"
                                        className={styles.coverImg} />
                                ) : (
                                    <div className={styles.defaultCover}></div>
                                )}
                                {
                                    isPlaying && (
                                        <div className={styles.musicRhythm}>
                                            <img src={MusicRhythmIcon} className={styles.musicRhythmIcon} />
                                        </div>
                                    )
                                }
                                <div className={styles.duration}>
                                    {duration ? `${Math.floor(duration / 60)}:${Math.floor(duration % 60)}` : '00:00'}
                                </div>
                                <div className={styles.playBtnBox}>
                                    <div className={styles.playBtn}>
                                        <AudioPlayer
                                            audioSrc={convertToCloudFrontUrl(content?.src)}
                                            playerType={content?.playerType}
                                            isPlaying={isPlaying}
                                            setIsPlaying={setIsPlaying}
                                            currentAudioEleId={elementData.id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.smallInfo}>
                                <div className={styles.title}>{content?.title}</div>
                                <div className={styles.artist}> {containsVariablePattern(content?.artist) ?
                                    "作者: " + rewriteText(content?.artist) : "作者: " + content?.artist}</div>
                            </div>
                        </div>
                    )
                }
                {
                    content?.playerType === 'small_horizontal' && (
                        <div className={styles.smallHorizontalAudioBox}>
                            <div className={styles.smallHorizontalCover}>
                                {content?.songCoverUrl ? (
                                    <img
                                        src={content?.songCoverUrl}
                                        alt="song cover"
                                        className={styles.coverImg} />
                                ) : (
                                    <div className={styles.defaultCover}></div>
                                )}
                                {
                                    isPlaying && (
                                        <div className={styles.musicRhythm}>
                                            <img src={MusicRhythmIcon} className={styles.musicRhythmIcon} />
                                        </div>
                                    )
                                }
                            </div>
                            <div className={styles.smallHorizontalInfo}>
                                <div className={styles.title}>{content?.title}</div>
                                <div className={styles.artist}>
                                    {containsVariablePattern(content?.artist) ?
                                        "作者: " + rewriteText(content?.artist) : "作者: " + content?.artist}
                                </div>
                                <div className={styles.duration}>
                                    {duration ? `${Math.floor(duration / 60)}:${Math.floor(duration % 60)}` : '00:00'}
                                </div>
                            </div>
                            <div className={styles.playBtnBox}>
                                <div className={styles.playBtn}>
                                    <AudioPlayer
                                        audioSrc={convertToCloudFrontUrl(content?.src)}
                                        playerType={content?.playerType}
                                        isPlaying={isPlaying}
                                        setIsPlaying={setIsPlaying}
                                        currentAudioEleId={elementData.id}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    content?.playerType === 'medium' && (
                        <div className={styles.mediumAudioBox}>
                            <div className={styles.playRegion}>
                                <div className={styles.player}>
                                    <div className={styles.songCover}>
                                        {content?.songCoverUrl ? (
                                            <img src={content?.songCoverUrl} alt="song cover" />
                                        ) : (
                                            <div className={styles.defaultCover}></div>
                                        )}
                                    </div>
                                    <div className={styles.audio}>
                                        <AudioPlayer
                                            audioSrc={convertToCloudFrontUrl(content?.src)}
                                            playerType={content?.playerType}
                                            isPlaying={isPlaying}
                                            setIsPlaying={setIsPlaying}
                                        />
                                    </div>
                                </div>
                                <div className={styles.userOperation}>
                                    <div className={styles.like} onClick={clickLikeIcon}>
                                        {isLiked ? (
                                            <img src={LikeIcon} alt="" />
                                        ) : (
                                            <img src={UnLikeIcon} alt="" />
                                        )}
                                        {likeCount !== 0 && <div className={styles.likeCount}>{likeCount}</div>}
                                    </div>
                                    <div className={styles.share} onClick={clickShareIcon}>
                                        <Tooltip
                                            color='white'
                                            trigger='click'
                                            title={<QRCode
                                                value={qrUrl} size={150}
                                                status={qrLoading ? "loading" : "active"} />
                                            }
                                        >
                                            <img src={ShareIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                    <div className={styles.download} onClick={clickDownloadIcon}>
                                        <img src={DownloadIcon} alt="Download" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.infoRegion}>
                                <div className={styles.songName}>{content?.title}</div>
                                <div className={styles.artist}>{containsVariablePattern(content?.artist) ?
                                    "作者: " + rewriteText(content?.artist) : "作者: " + content?.artist}</div>
                                <div className={styles.lyricBox}>
                                    <div className={styles.lyric}>
                                        <div className={styles.content}>
                                            {content?.lyrics}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </BaseDragableElement>
    );
};

export default observer(AudioRegion);
