import service from "@/services/axios";

const ApiPath = {
    USER_LIKE_OBJECT: "/like_object",
    USER_UNLIKE_OBJECT: "/unlike_object",
    GET_LIKE_CNT: "/get_like_cnt",
    USER_SHARE_OBJECT: "/share_object",
    GET_SONG_INFO: (userId: number, songId: number) => `/song/${userId}/${songId}`,
    CREATE_SHARE_LINK: "/share_link",
    GET_SHARE_LINK: (md5: string) => `/share_link/${md5}`,
    LIKE_OBJECT_AND_UPDATE: "/like_object_and_update",
    UNLIKE_OBJECT_AND_UPDATE: "/unlike_object_and_update",
    CREATE_USER_WORK: '/user_work',
}

export const userLikeObject = (data: any): Promise<any> => {
    return service.post(ApiPath.USER_LIKE_OBJECT, data);
}

export const userUnlikeObject = (data: any): Promise<any> => {
    return service.post(ApiPath.USER_UNLIKE_OBJECT, data);
}

export const getLikeCnt = (data: any): Promise<any> => {
    return service.get(ApiPath.GET_LIKE_CNT, { params: data });
}

export const userShareObject = (data: any): Promise<any> => {
    return service.post(ApiPath.USER_SHARE_OBJECT, data);
}

export const getSongInfo = (userId: number, songId: number): Promise<any> => {
    return service.get(ApiPath.GET_SONG_INFO(userId, songId));
}

export const createShareLink = (data: any): Promise<any> => {
    return service.post(ApiPath.CREATE_SHARE_LINK, data);
}

export const getShareLink = (md5: string): Promise<any> => {
    return service.get(ApiPath.GET_SHARE_LINK(md5));
}

// This can return the latest like count
export const likeObjectAndUpdate = (data: any): Promise<any> => {
    return service.post(ApiPath.LIKE_OBJECT_AND_UPDATE, data);
}

// This can return the latest like count
export const unlikeObjectAndUpdate = (data: any): Promise<any> => {
    return service.post(ApiPath.UNLIKE_OBJECT_AND_UPDATE, data);
}

export const createUserWork = (data: any): Promise<any> => {
    return service.post(ApiPath.CREATE_USER_WORK, data);
}